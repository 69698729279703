import React from "react";
import Layout from "../../shared/components/organisms/layout/layout";
import TermsContent from "../../components/organisms/terms-content/terms-content";
import useMobie from '../../shared/hooks/useMobile'
import SEO from '../../shared/components/atoms/seo/seo';

const Registro = (props) => {

  // send active modal to layout
  const termsInfo = props.pageContext
  const [modalActive, setModalActive] = React.useState(null);
  const isMobile = useMobie();

  return (
    <Layout modalActive={modalActive} shopButton={!isMobile}>
      <SEO
        title={termsInfo.metaTitulo ? termsInfo.metaTitulo : ''}
        description={termsInfo.metaDescripcion ? termsInfo?.metaDescripcion?.metaDescripcion : ''}
        slug={termsInfo.slug ? termsInfo.slug : ''}
        lang="ES"
      />
      <TermsContent termsInfo={termsInfo} />
    </Layout>
  )
}

export default Registro
