import React from 'react'
import useMobile from '../../../shared/hooks/useMobile'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import RichText from '../../../shared/components/atoms/rich-text/rich-text'
import './terms-content.scss'

const ProductContent = (props) => {

  const { terminosYCondiciones } = props.termsInfo
  const isMobile = useMobile();

  return (
    <section className="f-terms-conditions-section">
      <div className="f-breadcrum-position"><BreadCrumb mobile={isMobile} /></div>

      <div className="f-terms-content">
        {terminosYCondiciones &&
          <RichText
            className="f-article-body"
            jsonInfo={terminosYCondiciones.json}
          />
        }
      </div>
    </section>
  )
}

export default ProductContent
